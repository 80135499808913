import React from "react";

import Container from '../container';

import styles from './bar.module.scss';

const SharingBar = ({ url, summary }) => {
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&summary=${summary}&source=LinkedIn`;
  const xingUrl = `https://www.xing.com/spi/shares/new?url=${url}`;
  const twitterUrl = `https://twitter.com/intent/tweet/?text=${summary}%20-%20${url}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

  return (
    <div className={styles.bar}>
      <Container>
        <div className={styles.headline}>Diesen Beitrag teilen</div>
        <div className={styles.networks}>
          <a href={linkedInUrl} target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin-in"/>
          </a>
          <a href={xingUrl} target="_blank" rel="noreferrer">
            <i className="fab fa-xing"/>
          </a>
          <a href={twitterUrl} target="_blank" rel="noreferrer">
            <i className="fab fa-twitter"/>
          </a>
          <a href={facebookUrl} target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-f"/>
          </a>
        </div>
      </Container>
    </div>
  )
};

export default SharingBar;
