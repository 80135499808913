import React from "react";
import { Link, graphql } from 'gatsby';

import Container from '../../components/container';
import Layout from '../../components/layout/index';
import PostGrid from '../../components/post/grid';
import SectionsRenderer from '../../components/sections/renderer';
import Seo from '../../components/seo';
import SharingBar from '../../components/sharing/bar';
import { pathTo } from '../../routes';
import styles from './index.module.scss';

const Post = ({
  data: {
    post,
    post: {
      title='Please add Title',
      slug,
      teaser,
      sections=[],
      categories=[],
      metaTitle='',
      metaDescription,
      metaKeywords=[],
      sharingTitle='',
      sharingDescription,
      sharingImage
    },
    similarPosts,
    site
  }
}) => {
  const posts = similarPosts.edges.map(post => post.node);

  return (
    <>
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDescription={metaDescription && metaDescription.metaDescription}
        sharingTitle={sharingTitle}
        sharingDescription={sharingDescription && sharingDescription.sharingDescription}
        sharingImage={sharingImage && sharingImage.file && sharingImage.file.url}
        currentUrl={pathTo({ __typename: 'ContentfulPage', slug: slug })}
        keywords={metaKeywords}
      />
      <Layout>
        <header className={styles.header}>
          <Container>
            {( categories.length > 0 &&
              <div className={styles.categories}>
                {categories.map((category, index) => <div key={index}>{category}</div>)}
              </div>
            )}
            <h1 className={styles.title}>{title}</h1>
          </Container>
        </header>
        <div className={styles.sections}>
          <SectionsRenderer sections={sections}/>
        </div>
        <div className={styles.sharing}>
          <SharingBar url={`${site.siteMetadata.siteUrl}${pathTo(post)}`} summary={teaser && teaser.teaser}/>
        </div>
        <div className={styles.posts}>
          <PostGrid title='Weiterlesen' posts={posts || []}/>
        </div>
      </Layout>
    </>
  )
}

export default Post;

export const query = graphql`
  query PostQuery($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    post: contentfulPost(id: { eq: $id}) {
      __typename
      title
      slug
      teaser {
        teaser
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      sharingTitle
      sharingDescription {
        sharingDescription
      }
      sharingImage {
        ...Image
      }
      metaKeywords
      sections {
        __typename
        ...Photos
        ...Quote
        ...Text
      }
      categories
    }

    similarPosts: allContentfulPost(limit: 3) {
      edges {
        node {
          ...PostGridItem
        }
      }
    }
  }
`;
